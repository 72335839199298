.composition-box, .share-box {
  display: flex;
  justify-content: space-between;

  .title-edit-box {
    display: flex;
    align-items: flex-start;

    .title {
      font-size: 1.9rem;
    }

    .btn {
      padding: 0 5px;
      margin-left: 5px;
      font-size: 0.8rem;
    }
  }

  .type {
    font-size: 16px;
    display: block;
    line-height: 25px;
  }

  .last-edited {
    line-height: 30px;
    font-size: 12px;
    color: #757575;
    display: block;
    font-weight: 500;
  }

  .alternate-names {
    line-height: 20px;
    font-size: 14px;
    color: #757575;
    display: block;
    font-weight: 500;
  }

  .share-info {
    flex: 1;
  }

  .share-box-badge {

    &.full-width {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }

    .wrapper {
      padding: 5px 20px;
      border-left: 2px solid $primary;
      background-color: $white;
      @include exp-box-shadow;
      display: flex;
      align-items: center;
    }

    .icon {
      font-size: 28px;
      margin-right: 20px;
      color: $grey-500;
    }

    .info {
      color: $grey-700;
      strong { color: $grey-800; }
    }

    span {
      display: block;
    }

    .btn {
      padding: 0;
      margin-right: 10px;
    }
  }

  .edit-form {
    width: 100%;
  }
}