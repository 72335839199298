.btn {
  padding-left: 15px;
  padding-right: 15px;
  font-size: .9rem;
}

a.btn {
  color: $grey-800;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: $white !important;

  &:hover {
    background-color: darken($primary, 5%);
    border-color: darken($primary, 5%);
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    background-color: lighten($primary, 5%);
    border-color: lighten($primary, 5%);
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: $white !important;

  &:hover {
    background-color: darken($secondary, 5%);
    border-color: darken($secondary, 5%);
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    background-color: lighten($secondary, 5%);
    border-color: lighten($secondary, 5%);
  }
}

.btn-default {
  background-color: $grey-100;
  border-color: $grey-300;
  color: $grey-800 !important;
  font-size: 0.9rem;

  &:hover {
    background-color: darken($grey-100, 5%);
    border-color: darken($grey-300, 5%);
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    background-color: lighten($grey-100, 5%);
    border-color: lighten($grey-300, 5%);
  }
}

.btn-blue-gray {
  background-color: $blue-gray-100;
  border-color: $blue-gray-100;
  color: $grey-800;

  &:hover {
    background-color: darken($blue-gray-100, 5%);
    border-color: darken($blue-gray-100, 5%);
    cursor: pointer;
    color: $grey-800;
  }

  &:disabled,
  &.disabled {
    background-color: lighten($blue-gray-100, 5%);
    border-color: lighten($blue-gray-100, 5%);
    color: $grey-800;
  }
}

.btn-icon {
  border: 1px solid #dedede;
  margin-right: 5px;
}

.icon-only {
  padding-left: 12px;
  padding-right: 12px;
}

.griddle-cell {
  
  .btn-group {
    
    .dropdown-menu {
      min-width: 100px;
      margin: 0;
      padding: 0;
      border: 1px solid #dedede;
      text-align: center;

      .dropdown-divider {
        margin: 0;
      }
    }
  }
}

.btn-group-sm {
  font-size: $sm-font-size;

  .dropdown-menu {
    font-size: $sm-font-size;   
  }
}

.btn-light-border {
  border: 1px solid #dedede;
}

.btn-shadow {
  -webkit-box-shadow: 0 2px 5px -3px rgba(122,122,122,0.52);
  -moz-box-shadow: 0 2px 7px -3px rgba(122,122,122,0.52);
  box-shadow: 0 2px 7px -3px rgba(122,122,122,0.52);
}

.btn-wide {
  padding-left: 35px;
  padding-right: 35px;
}

.btn-submit {
  margin-top: 10px;
}

.btn-edit {
  background-color: $blue;
  color: white;
  &:hover {
    background-color: darken($blue, 5%);
    border-color: darken($blue, 5%);
    cursor: pointer;
    color: white;
  }
}

.btn-delete {
  background-color: transparent;
  color: $primary;
  border-color: $primary;

  &:hover {
    background-color: darken($primary, 5%);
    border-color: darken($primary, 5%);
    cursor: pointer;
    color: white;
  }
}

.btn-remove {
  color: #ea5c5c;
  font-size: 13px;
  font-weight: 600;
}


.btn-login {
  text-transform: uppercase;
  font-size: 14px;
}

.btn-google {
  text-transform: uppercase;
  font-size: 13px;
  background-color: $google-blue;
  color: $white !important;

  &:hover {
    background-color: darken($google-blue, 5%);
    border-color: darken($google-blue, 5%);
    cursor: pointer;
    color: white;
  }
}

.btn-google-green {
  text-transform: uppercase;
  font-size: 13px;
  background-color: $google-green;
  color: $white !important;

  &:hover {
    background-color: darken($google-green, 5%);
    border-color: darken($google-green, 5%);
    cursor: pointer;
  }
}

.btn-group {
  &.inline {
    display: inline-block;
  }
}

.btn-icon-group {
  .btn:first-child {
    padding-right: 5px;
    padding-left: 5px;
    vertical-align: middle;

    svg {
      margin-top: 2px;
    }
  }
}

.btn-icon-group-sm {
  .btn {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
  }

  .btn:first-child {
    padding-right: 5px;
    padding-left: 5px;
  }

  .btn:nth-child(2) {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.btn-icon-group-secondary {
  .btn {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: darken($secondary, 5%);
      border-color: darken($secondary, 5%);
      cursor: pointer;
    }
  }

  .btn:first-child {
    background-color: darken($secondary, 5%);
  }
}

.btn-icon-group-green {
  .btn {
    background-color: $google-green;
    color: $white !important;

    &:hover {
      background-color: darken($google-green, 5%);
      border-color: darken($google-green, 5%);
      cursor: pointer;
    }
  }

  .btn:first-child {
    background-color: darken($google-green, 5%);
  }
}

.box-tools, .page-tools {
  .btn {
    padding: 7px 20px;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $grey-700;
  }
}

.page-nav {
  .btn {
    padding: 7px 15px;
  }
}

.btn-hover {
  background-color: $grey-200;
}

.btn-hover-primary {
  &:hover {
    background-color: $primary;
    color: white !important;
  }
}

.btn-hover-secondary {
  &:hover {
    background-color: $secondary;
    color: white !important;
  }
}

.btn-hover-danger {
  &:hover {
    background-color: $danger;
    color: white !important;
  }
}

.btn-danger {
  background-color: $danger;
  color: white !important;

  &:hover {
    background-color: darken($danger, 10%);
  }
}

.cc-btn {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,.05), 0 1px 3px 0 rgba(0,0,0,.05);
}

.btn-success {
  color: $white !important;
}

.btn-xs {
  padding: 3px 10px;
  font-size: 12px;
}

.btn-text {
  font-weight: 500; 
}

.btn-link {
  font-size: 12px;
  font-weight: 500;
  color: $blue-gray-400;
  text-decoration: none;

  &:hover {
    color: $link-color;
  }
}

.btn-border {
  background-color: $grey-50;
  border: 1px solid $grey-300;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem 1.25rem;
  color: $grey-700 !important;

  &.btn-border-primary {
    &:hover {
      border: 1px solid $primary;
      color: $primary !important;
    }
  }

  &.btn-border-secondary {
    &:hover {
      border: 1px solid $secondary;
      color: $secondary !important;
    }
  }

  &.btn-border-danger {
    &:hover {
      border: 1px solid $danger;
      color: $danger !important;
    }
  }
}

.btn-form {
  border: 1px solid #ccc;
  font-size: 12px;
  font-weight: 500;
  color: $blue-gray-400;
  text-decoration: none;
}

.input-group-append .btn,
.input-group-prepend .btn {
  z-index: unset;
}