@import '../../../../../styles/variables';
$btn-height: 20;
$btn-width: 250;

.dropzone {
  width: 100%;
  text-align: center;
  min-height: 200px;
  border-radius: 10px;
  position: relative;
  font-size: 14px;

  .btn {
    width: $btn-width + px;
    line-height: 1;
    cursor: pointer;
    font-size: 14px;

    svg {
      vertical-align: bottom;
    }

  }

  .dropzone-text {
    width: $btn-width + px;
    position: absolute;
    top: -webkit-calc(50% - 40px);
    top:    -moz-calc(50% - 40px);
    top:         calc(50% - 40px);
    left: -webkit-calc(50% - 125px);
    left:    -moz-calc(50% - 125px);
    left:         calc(50% - 125px);

    span {
      display: block;
      margin-bottom: 5px;
    }
  }

  .dropzone-btn {
    width: $btn-width + px;
    line-height: 1;
    cursor: pointer;
    font-size: 14px;

    svg {
      vertical-align: bottom;
    }
  }

}

.ReactTable .rt-td {
  white-space: pre-line !important;
  word-wrap: break-word;
  overflow: visible;
}

._header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: $grey-800;
}

.advanced {
  padding-top: 18px;
}

.gform {
  margin-left: 110px;
  margin-bottom: 15px;

  .btn {
    margin-top: -1px;
  }

  .input {
    height: 30px;
  }
}