
@import 'base';
//@import '~normalize.css/normalize';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

textarea, select, input, button, div { outline: none; }


.container__wrap,
.main-wrapper {
  height: 100%;
}
