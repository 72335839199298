@import '../../../../../styles/mixins';
$search-bar-color:      #f1f3f4;

.search-form-inline {
  display: inline-block;  

  .input-group {
    width: 100%;

    .search-input {
      border: none;
      background: white;
      height: 50px;
      padding: 5px 15px;
      font-size: 0.8em;
      border-radius: 5px;
    }
  }
}

.search-input-group {
    background-color: $search-bar-color;
    border-radius: 5px;
  
    .icon {
      font-size: 18px !important;
    }
  
    .input-group-text {
      border: 0;
      padding: .375rem .5rem;
      padding-right: .3rem;
      background-color: $search-bar-color;
    }
  
    .form-control {
      border: 0;
      font-size: 1em;
      background-color: $search-bar-color;
  
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  
  
    .Select {
      background-color: rgb(55, 122, 236);
      min-width: 170px;
      outline: 0 !important;
      border: 0 !important;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 0 10px;
      color: white;
      
      .Select-control {
        background-color: inherit;
        color: inherit;
        border: 0;
        &:focus-within {
          background-color: inherit;
          color: inherit;
        }
        &:hover {
          outline: 0 !important;
          border: 0 !important;
          box-shadow: none;
        }
        .Select-multi-value-wrapper{
          background-color: inherit;
          color: inherit;
          .Select-value{
            color: inherit;
            background-color: inherit;
            .Select-value-label{
              background-color: inherit;
              color: inherit;
              
            }
          }
        }
        .Select-clear-zone {
          color: inherit;
          .Select-clear{
            color: inherit;
          }
        }
      }
      .Select-menu-outer {
        border-left: 0px;
      }
  
    }
  
    &.big-search {
      margin-top: 20px;
  
      .form-control {
        font-size: 1em;
        padding: 23px 0;
        background-color: $search-bar-color;
      }
  
      .Select {
        padding: 23px 10px;
        font-size: 16px;
        min-width: 200px;
      }
  
      .input-group-text {
        padding: .375rem 1rem;
      }
  
      .btn-search {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 30px;
        padding-right: 30px;
      }
  
      .Select-clear { display: none; }
      .Select-arrow { border-color: white transparent transparent; }
    }
  }

.btn-default {
  background-color: #EEEEEE;
  border-color: #EEEEEE;
  color: #424242 !important;
  font-size: 1rem !important;

  &:hover {
    background-color: darken(#EEEEEE, 5%);
    border-color: darken(#EEEEEE, 5%);
    cursor: pointer;
  }

}

.go {
  cursor: pointer;
}
