.spinner {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
  } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
  }
}

.lds-facebook {
  position: relative;
  width: 45px;
  height: 38px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 7px;
  background: $primary;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 18px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 30px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 17px;
    height: 4px;
  }
  50%, 100% {
    top: 7px;
    height: 24px;
  }
}
