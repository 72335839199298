.ownership-totals {
  margin: 10px 0;

  > div {
    display: flex;
    align-items: center;

    span {
      font-size: 1.3rem;
      margin-left: 10px;
    }
  }
}