$error:       #d82600;

.notif-bar {
  min-height: 30px;
  line-height: 30px;
  background-color: $blue;
  color: #ffffff;
  padding: 10px;

  &.error-bar {
    background-color: $error;
    color: #ffffff;
  }
}