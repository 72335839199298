/* ./src/styles/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

textarea, select, input, button, div { outline: none; }

.container__wrap,
.main-wrapper {
  height: 100%;
}
