.flex-1 { flex-grow: 1; }
.flex-2 { flex-grow: 2; }
.flex-3 { flex-grow: 3; }

.flex-align-right {
  justify-content: flex-end;
}

.flex-align-left {
  justify-content: flex-start;
}