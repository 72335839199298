@import '../../styles/variables';

.select-toggle {
  width: 100%;

  .btn-group {
    display: flex;

    .btn {
      flex: 1;
    }
  }

  .btn-option {
    border: 1px solid #dbe3f4;
    color: $grey-700;
    font-size: 14px;
    min-height: 36px;
    line-height: 36px;
    padding-top: 0;
    padding-bottom: 0;

    &.selected {
      background-color: #fafafa;
      font-weight: 500;
      color: $google-green;
    }
  }
}