/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 3.5rem tall */
body {
  //padding-top: $navbar-height;
}

/*
 * Typography
 */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid #eee;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

/*
 * Dashboard
 */

 /* Placeholders */
.placeholders {
  padding-bottom: 3rem;
}

.placeholder img {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}


.content-wrapper {
  padding: 0 !important;

  &.roy-content-wrapper {
    padding-left: 70px !important;
  }
}

.side-panel {
  border-left: 1px solid #e0e0e0;
  padding: 20px;
  background-color: white;
  
  .bm-cross {
    display: none;
  }

  .bm-item:focus {
    box-shadow: none !important;
  }
}

.table-description {
  padding: 20px;
  .info {
    width: 100%;
    display: flex;
    font-size: 0.85rem;
    align-items: center;

    label {
      text-transform: uppercase;
      font-weight: 600;
      color: $grey-800;
      flex: 1;
    }

    .form-control {
      display: inline-block;
      flex: 2;
    }

    .info {
      display: inline-block;
      color: $grey-600;
      flex: 2;

      &::before {
        content: ':';
        margin-right: 10px;
      }
    }
  }
}

.wrapper-pad {
  padding: 20px;
}

.wrapper-pad + .wrapper-pad {
  padding-top: 0;
}