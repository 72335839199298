@mixin spacer-mixin($value) {
  width: 100%;
  padding: $value;
}

.spacer5 {
  @include spacer-mixin(5px);
}

.spacer10 {
  @include spacer-mixin(10px);
}

.spacer15 {
  @include spacer-mixin(15px);
}

.spacer20 {
  @include spacer-mixin(20px);
}

.spacer30 {
  @include spacer-mixin(30px);
}

.label-spacer {
  @include spacer-mixin(12px);
}

.tab-lt-1 {
  padding-left: 1em;
}