@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.profile-box {
  background-color: #ffffff;
  display: flex;
  width: 100%;

  .profile-wrapper {
    padding: 30px 20px;
    width: 100%;
  }

  .main-text {
    display: block;
    font-size: 30px;
    color: $grey-800;
    text-align: center;
  }

  .subtext {
    display: block;
    text-align: center;
    font-size: 16px;
    color: $grey-700;
  }

  .photo {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .holder {
      background-position: center center;
      height: 150px;
      width: 150px;
      border-radius: 50%;
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      color: $google-green;
      border: 2px solid #ffffff;
    }
  }

  .detail {
    font-size: 12px;
    text-align: center;
    label {
      font-weight: 600;
      display: inline-block;
      margin-right: 5px;
    }   
  }
}