$grey-300:                  #E0E0E0;
$grey-700:                  #616161;
$grey-800:                  #424242;

.header-search-form {
  .input-group {
    height: 69px;
    border: 0;
    width: 80%;
    float: left;

    .input-group-prepend {
      .input-group-text {
        background-color: transparent;
        border: 0;
        padding-left: 20px;
        padding-right: 0px; 
      }
    }

    .form-control {
      border: 0;
      padding-right: 0px; 

      &::placeholder {
        color: $grey-700;
        font-size: 14px;
      }
    }
  }
}