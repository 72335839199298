@import '../../../../styles/variables';

.total-shares {
  margin-top: 10px;
  margin-right: 40px;
  font-size: 12px;
  display: inline-block;

  ._wrapper {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 18px;
    margin-left: 5px;
  }
}

.form-tip {
  color: $blue-gray-200;
  cursor: pointer;
}

.title-form-group {
  display: flex;
  align-items: center;

  .div-form-control {
    flex: 1;
  }

  .custom-control {
    min-width: 50px;
    margin: 0 10px;

    .custom-control-label {
      &::before { top: 0; }
      &::after { top: 2px; }
    }
  }
}

.composition-select {
  .input-group {
    .input-group-prepend {
      .form-check-input {
        margin-left: 0;
        margin-right: 10px;
        position: unset;
      }
    }
  }
}
