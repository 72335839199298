.page {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .page-title {
    display: inline-block;
    .main {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .page-tools {
    .btn {      
      margin-right: 10px;
      color: $grey-700;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .page-nav {
    flex: 1;
    display: flex;
    align-items: center;

    .btn {
      display: inline-block;
    }
  }

  .page-actions {
    .btn {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}