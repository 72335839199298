.selectize-div {
  &.is-invalid {
    .Select-control {
      border-color: #f62400;
    }

    .invalid-feedback {
      display: block;
    }
  }

  .Select--multi .Select-value {
    border: 0;
  }
}