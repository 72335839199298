$grey-300:                  #E0E0E0;
$grey-700:                  #616161;
$grey-800:                  #424242;
$search-bar-color:          #f1f3f4;
@import '../../../../styles/mixins';

.header-search-form-modal {
  @include exp-box-shadow;
  background-color: $search-bar-color;
  border-radius: 5px;
  display: inline-block; 

  .input-group {
    height: 40px;
    border: 0;
    width: 350px;
    float: left;

    .input-group-prepend {
      .input-group-text {
        border: 0;
        padding-left: 20px;
        padding-right: 0px; 
      }
    }

    .form-control {
      border: 0;
      padding-right: 0px; 

      &::placeholder {
        color: $grey-700;
        font-size: 14px;
      }
    }
  }
}

.grey {
  background-color: $search-bar-color;
   
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: $search-bar-color;
  }
}