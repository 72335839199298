@import '../../../styles/variables';

.permission-form {
  .form-section {
    margin-bottom: 15px;
    background-color: #ffffff;
    padding: 15px 20px;
  }

  .form-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: $blue-gray-800;
    margin-bottom: 5px;
  }

  .form-checkboxes {
    font-size: 14px;
    .form-check {
    }
  }
}