$navTab:                  #e7eef2;

.nav-tabs {
  border-radius: 0;
  border: 0;
  .nav-item {
    cursor: pointer;
    background-color: $navTab;
    &.show {
      .nav-link {
        border: 0;
      }
    }
  }

  .nav-link {
    padding: 0.7rem 0;
    font-size: 14px;
    color: $grey-600;
    border-radius: 0;
    border-top: 3px solid transparent;
    z-index: 0.5;

    span {
      display: inline-block;
      padding: 0 1.5rem;
      border-right: 1px solid darken($navTab, 10%);
      text-transform: uppercase;
      color: $grey-600;
      font-size: 0.8rem;
      font-weight: 500;
    }

    &:hover, &:focus {
      border-color: transparent;
    }

    &.active {
      border: 0;
      border-top: 3px solid $primary;
      margin-left: -2px;
      box-shadow: 5px 0 5px -5px lighten($grey-500, 10%);
      z-index: 0;

      span {
        border-right: 0;
        font-weight: 600;
        color: $grey-700;
      }
    }

    &:last-child {
      span { border-right: 0; }
    }

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.tab-content {
  background-color: $white;

  .tab-form {
    padding: 0 20px;
  }

  .tab-actions {
    display: flex;
    padding: 20px;
    padding-bottom: 0px;

    &._right {
      justify-content: flex-end;
    }

    .btn {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .tab-title {
      display: flex;
      .title {
        display: block;
        font-size: 1.2rem;
        margin-right: 10px;
      }
    }

    .tab-actions {
      padding: 0;
    }
  }

  .tab-body {
    padding: 0 20px;

    &.no-pad {
      padding: 0;
    }
  }
}