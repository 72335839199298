.btn {
  border-radius: .15rem;
}

dd {
  margin-bottom: 0;
}

.dropdown-menu {
  border-radius: 0;
  border-top: 0;

  &.right {
    right: 0;
    left: auto;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.left-submenu {
  float: none;
}

.left-submenu > .dropdown-menu {
  border-radius: 6px 0px 6px 6px;
  left: auto;
  margin-left: 10px;
  right: 98%;
}

.text-center {
  text-align: center;
}

.double-header {
  thead {
    tr > th {
      padding: .5rem .75rem;
    }

    tr:first-child > th {
      border-bottom: 0 !important;
    }

    tr:last-child > th {
      border-top: 0 !important;
    }
  }
}

.shadow {
  -webkit-box-shadow: 0px 3px 5px -5px rgba(122,122,122,0.52);
  -moz-box-shadow: 0px 3px 5px -5px rgba(122,122,122,0.52);
  box-shadow: 0px 3px 5px -5px rgba(122,122,122,0.52);
}

.paginator {
  border: 1px solid #dedede;
  margin: 0 0;
  margin-top: 10px;
  padding: 0 5px;
  padding-top: 10px;

  >div {
    margin: 0;
    padding: 0;
  }

  .actions {

  }

  .paginator-text {
    text-align: right;
  }

  a {
    display: inline-block;
    cursor: pointer;

    svg, i { color: $primary; }
  }

  span {
    display: inline-block;
    font-size: 14px;
    margin: 0 10px;
  }

  input {
    max-width: 50px;
    display: inline-block;
    text-align: center;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.no-padding {
  padding: 0 !important;
}

.icon-success {
  ion-icon {
    color: $google-green;
  }
}

.pretty {
  font-size: 14px;
}

.alert {
  border-radius: 0;
  border: 0;
}

input:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.alert {
  font-size: 0.85rem;

  .btn-link {
    padding: 0 5px;
  }
}

.query-controlled {
  text-align: center;
  // display: flex;
  // align-items: center;

  .query {
    display: block;
    width: 100%;
    flex: 1;
  }

  .btn {
    margin: 10px;
  }
}