/*
 * COLORS
 */

$black:                     #000000;
$white:                     #ffffff;
$primary:                   #f62400;
$blue:						          #0BA1FE;
$danger:                    #E65100;
$info:                      #81D4FA;

$sidebar-bg:                #FAFAFA;

$border-light:              #E0E0E0;

$form-label:                #757575;

$grey-50:                   #FAFAFA;
$grey-100:                  #F5F5F5;
$grey-200:                  #EEEEEE;
$grey-300:                  #E0E0E0;
$grey-400:                  #BDBDBD;
$grey-500:                  #9E9E9E;
$grey-600:                  #757575;
$grey-700:                  #616161;
$grey-800:                  #424242;
$grey-900:                  #212121;

$blue-gray-50:              #ECEFF1;
$blue-gray-100:             #CFD8DC;
$blue-gray-200:             #B0BEC5;
$blue-gray-400:             #78909C;
$blue-gray-500:             #607D8B;
$blue-gray-600:             #546E7A;
$blue-gray-700:             #455A64;
$blue-gray-800:             #37474F;

$secondary:                 #4885ed;
$secondary-variant:         #0C6FB2;

$tertiary:                  #B9FF19;

$google-green:              #3cba54;
$google-yellow:             #f4c20d;
$google-blue:               #4885ed;
$google-red:                #de5426;

/*
 * FONTS
 */

$sm-font-size:              .875rem;

$link-color:                #2196F3;

/*
 * NAVBAR
 */

$navbar-height:             60px;
