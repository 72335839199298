.DayPickerInput {
  display: block;
}

.datepicker {
  &.is-invalid {
    .invalid-feedback {
      display: block;
    }
  }
}