.ownership-section {
  .header {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;

    .btn {

    }
  }
}