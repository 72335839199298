@import '../../../../../styles/variables';

.export-form-wrapper {
  .stats {
    font-size: 48px;
    display: block;
  }

  .last_updated {
    font-size: 14px;
    color: $grey-700;
    margin-bottom: 10px;
    display: block;
  }
}