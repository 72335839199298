$input-color:             #ffffff;
$input-border:            #cccccc;

$disabled-input-color:    #f2f2f2;
$disabled-input-border:    #e6e6e6;

.form-tip {
  display: inline-block;
}

.form-group {
  margin-bottom: 5px;

  .p-label {
      font-size: 16px;
      color: black;
      text-transform: none;
    }

  label {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: $form-label;
    margin-bottom: 0;
  }

  .form-label-actions {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .form-tip {
    color: $form-label !important;
    font-size: 0.8rem;
    cursor: pointer;
  }
}

.exp-input-group {
  .input-group-text {
    font-size: 16px;
    min-width: 40px;
    text-align: center;
    background-color: white;
  }
}

.form-control,
.Select-control {
  border-radius: 2px !important;
  min-height: 35px !important;
  border-color: $input-border;
}

.form-control {
  padding: 0.28rem .75rem;
  height: auto;
}

.Select-control {
  line-height: 1.4;
  display: flex;
  align-items: center;

  .Select-multi-value-wrapper {
    flex: 1;
  }

  .Select-clear {
    line-height: unset;
  }

  .Select-arrow-zone {
    i {
      color: $blue-gray-600;
    }
  }
}

.exp-form {
  .form-control,
  .Select-control {
    background-color: $input-color;

    &.is-disabled {
      background-color: $disabled-input-color;
      border-color: $disabled-input-border;
    }
  }

  .Select.is-disabled > .Select-control {
    background-color: $disabled-input-color;
    border-color: $disabled-input-border;
  }
}

.Select-control .Select-value,
.Select-placeholder {
  line-height: 38px !important;
}

.Select--multi .Select-value {
  line-height: 1.4 !important;
}

.required-icon {
  display: inline-block;
  padding: 0 3px;
  color: $primary;
}

.form-notes {
  color: $grey-500;
  display: inline-block;
  padding: 15px 10px;
  font-size: 12px;
}

form {
  table {
    border: 0;
  }
}

.table-form-header {
  margin-bottom: 10px;

  h5 {
    margin: 0;
    display: inline;
  }

  button {
    float: right;
  }
}


.form-section {
  .form-section-header {
    border-bottom: 1px solid $grey-200;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ._title {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 2px solid $grey-800;
      padding: 15px 10px;
      display: inline-block;
      letter-spacing: 1px;

      &.space-after {
        margin-right: 10px;
      }
    }

    ._actions {
      .btn {
        margin-right: 10px;
      }
    }
  }
}

.form-array-header {
  display: flex;
  align-items: center;

  ._title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
  }

  ._actions {
    margin-left: 10px;
  }
}

.form-actions {
  .btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.form-help {
  font-size: 12px;
  color: $blue-gray-400;
}

.radio-group {
  display: flex;
  margin: 5px 0;

  .radio-input {
    padding: 7px 15px;
    border: 1px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;

    .icon, label {
      margin-bottom: 0;
      margin-top: 0;
    }

    label {
      font-size: 0.9rem;
    }

    &.selected {
      border: 1px solid lighten($primary, 20%);
      background-color: #ffffff;
      @include exp-box-shadow;

      .icon, label {
        color: $primary;
      }

      label {
        font-weight: 500;
      }
    }
  }

  &.radio-group-inline {
    justify-content: flex-start;

    .radio-input {
      margin-right: 10px;
    }
  }
}

.form-help-text {
  color: $grey-600;
  font-size: 0.85rem;
}

.form-control-loading {
  display: flex;
  min-height: 38px;
  align-items: center;
}

.form-pad {
  padding: 15px;
}

.help-label {
  text-transform: none !important;
  font-weight: 400;
}

.selectize__value-container {
  padding: 2px 3px !important;
}

.selectize__placeholder {
  padding: 0 5px;
}

.platform-list {
  margin-top: 10px;
  .form-check {
    display: inline-block;
    margin-right: 15px;
  }
}
