$btn-height: 20;
$btn-width: 250;

.dropzone {
  width: 100%;
  text-align: center;
  min-height: 250px;
  border-radius: 10px;
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    min-height: 150px;
  }

  .btn {
    width: $btn-width + px;
    line-height: 1;
    cursor: pointer;
    font-size: 14px;

    svg {
      vertical-align: bottom;
    }

  }

  .dropzone-text {

    span {
      display: block;
      margin-bottom: 5px;
    }
  }

  .dropzone-btn {
    width: $btn-width + px;
    line-height: 1;
    cursor: pointer;
    font-size: 14px;

    svg {
      vertical-align: bottom;
    }
  }

  .uploaded-file {
    display: block;
    margin-top: 10px;
    padding: 5px 15px;
  }
}

.file-input {
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 2px;
  cursor: pointer;

  .file-input-wrapper {
    height: 100%;
  }

  .file-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .placeholder {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .placeholder-wrapper {
      width: 100%;
      text-align: center;
    }

    .placeholder-icon {
      margin-bottom: 10px;
      font-size: 45px;
    }

    .placeholder-text {
      display: block;
      font-size: 16px;
    }
  }

  .file-input-preview {
    ._icon {
      font-size: 24px;
      margin-right: 10px;
    }

    ._filename {
      display: inline-block;
      font-size: 18px;
      margin-right: 10px;
    }

    ._filesize {
      display: inline-block;
      font-size: 12px;
      color: #BDBDBD;
    }
  }
}