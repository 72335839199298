.share-cards {
  padding: 20px;

  .share-card {
    border: 1px solid $grey-200;
    margin-bottom: 10px;
    padding: 20px;

    .card-header {
      background-color: white;
      padding: 0;
      padding-bottom: 15px;
    }

    .title, .collector {
      display: block;
      text-decoration: none;
    }

    .title {
      font-size: 1.3rem;
    }

    .card-body {
      .col, .row {
        padding: 0;
      }
    }

    .info {
      margin-bottom: 10px;
      color: $grey-700;

      .label {
        display: block;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 600;
        color: $grey-500;
      }
    }

    .share-rate {
      .rate {
        font-size: 1.2rem;
        font-weight: 500;
      } 
    }
    
  }
}

.data-cards {
  display: flex;
  justify-content: space-evenly;
  
  ._card {
    padding: 10px;
    flex: 1;

    ._wrapper {
      border-right: 1px solid $grey-200;
    }

    &:nth-child(3n) {
      ._wrapper {
        border: 0;
      }
    }

    label {
      display: block;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 600;
      color: $grey-500;
    }

    .addtl-label {
      display: block;
      text-transform: uppercase;
      font-size: 0.5rem;
      font-weight: 600;
      color: $grey-500;
    }

    .value {
      display: block;
      font-size: 1.1rem;
    }

    .addtl-value {
      display: block;
      font-size: 0.9rem;
    }
  }
}