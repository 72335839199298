table {
  font-size: 14px;
} 

.borderless td, .borderless th {
  border: none;
}

.btn-edit-detail {
  color: white !important;
  margin-bottom: 10px;
  margin-left: 10px;
  float: right;
}

.table {
  margin-bottom: 0px;
  background-color: white;

  tr {
    td, th {
      vertical-align: middle;
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }

  &.table-sm {
    tr {
      td, th {
        padding: 10px;
      }
    }
  }

  .row-share {
    td, th {
      padding-bottom: 0px;
      padding-top: 10px;
    }
  } 

  th {
    font-weight: 500;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  thead {
    th {
      border-top: 0;
      padding: 15px;
      font-weight: 500;
      font-size: 13px;
    }
  }

  .first {
    td, th {
      border-color: $primary;
    }
  }

  .head {
    td, th {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.table-codes {
  tr {
    vertical-align: middle;
  }

  .thead-general {
    tr {
      th {
        text-transform: uppercase;
        padding: 7px 0;
        border-bottom: 1px solid $primary;
        font-size: 14px;
        height: 40px;

        span {
          display: inline-block;
        }
      }
    }
  }

  tbody {
    tr {
      td:first-child {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        color: $grey-700;
      }
    }
  }

  .btn {
    float: right;
    font-size: 12px;
    padding: 3px 10px;
  }
  .add-btn {
    float: left;
  }

  .rmv-btn {
    float: left;
  }

}

.table-actions {
  .btn {
    margin-right: 10px;
  }
}

.thead-general {
  font-weight: 500;
  font-size: 15px;
}

.thead-darker {
  background-color: #3E3E3E;
  color: white;
  .th-edit {
    cursor: pointer;
    text-align: right;
  }
}

.thead-primary {
  background-color: white;
  color: #424242;

  th {
    border-bottom: 2px solid $primary !important;
  }
}

.th-button {
  padding-right: 0px !important;
}

.table-composition {
  margin-bottom: 10px;
}

.table-body {
  padding:20px
}

.griddle-table { 
  tr {
    td {
      padding-bottom: 20px;
      padding-top: 20px;
    }
    th {
      padding-bottom: 15px;
    }
  }

  .btn-group {
    &.btn-group-sm {
      a.btn-sm {
        padding: 2px 15px;
      }
      button.btn-sm {
        padding: 2px 5px;
      }
    }
  }
}

.rt-table {
  .rt-thead {
    font-weight: 500;
    font-size: 13px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: center;
    border-bottom: 2px solid $primary;
  }

  .-header {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .-sort-asc, .-sort-desc {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.expdata-table {

  span, a {
    font-size: 0.85rem;
  }

  .rt-tr {
    text-align: left;
    display: flex;
    align-items: center;

    .rt-th {
      padding: 10px .75rem;
      white-space: normal;
      border: 0;
      text-align: left;
    }
    
    .rt-td {
      padding: 20px .75rem;
      white-space: normal;
      overflow: visible;
    }
  }

  .rt-thead {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: transparent;
    color: $grey-800;
    border-bottom: 2px solid $primary;
    .rt-tr {
      text-align: left;
      font-weight: 500;
    }
  }

  .rt-tbody {
    font-size: 14px;

    .rt-tr {
      .rt-td {
        padding: 20px .75rem;
        border: 0;
      }
    }
  }

  .pagination-bottom {
    .-pagination {
      border: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}

.paginator {
  padding: 5px;
  vertical-align: middle;
  background-color: #ffffff;

  .btn {
    color: $primary;
  }

  .pages {
    text-align: center;
    font-size: 1rem;
    padding-top: 5px;
  }
}

.griddle-noResults {
  padding: 15px 20px;
}

.table-header-buttons {
  .buttons {
    text-align: right;
    vertical-align: bottom;
  }
}

.td-align-center {
  text-align: center;
}

.table-striped {
  &.table-dynamic {
    tr:last-child td {
      background-color: white;
    }
  }
}

.table-forms {
  td {
    padding: 0;
    border: 0 !important;
  }

  tr {
    td:first-child {
      padding-left: 0 !important;
    }
  }
}

.table-select {
  .rt-tr {
    .rt-th:first-child,
    .rt-td:first-child {
      flex: 15 !important;
    }
  }
}

.ownership-table {
  border-bottom: 1px solid #dedede;
  margin-bottom: 10px;
}

.table-exp {
  thead {
    tr th {
      border-bottom: 2px solid $primary;
    }
  }
}

.table-writer,
.table-collector,
.table-owner {
  display: block;
  color: $blue-gray-600;
}

.table-asset-codes {
  font-size: 0.8rem;
  color: $blue-gray-600;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.table-border {
  border: 1px solid $grey-200;
}

.share-table {
  font-size: 12px;
  margin-top: 20px;
  width: 100%;

  tr {
    th:first-child,
    td:first-child {
      padding-left: 20px;
    }

    th:last-child,
    td:last-child {
      padding-left: 20px;
    }

    th {
      vertical-align: bottom;
      color: $grey-700;
    }

    th, td {
      padding: 0;
    }
  }

  .ReactTable {
    border: 0;
    font-size: 14px;

    .-pagination {
      box-shadow: none;
      border: 0;
    }

    .rt-tr {
      .rt-th {
        border: 0;
        padding: 10px;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      .rt-td {
        border: 0;
        border-top: 1px solid #dee2e6;
        padding: 10px;
        overflow: visible;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }

    .rt-tbody {
      .rt-tr-group {
        border: 0;

        &:first-child {
          .rt-tr {
            .rt-td {
              border: 0;
            }
          }
        }
      }
    }
  }
}