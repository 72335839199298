@import '../../styles/variables';

.icon {
  display: inline-block;

  &.space-after {
    margin-right: 5px;
  }

  &.space-before {
    margin-left: 5px;
  }

  &.icon-success {
    color: $google-green !important;
  }

  &.icon-danger {
    color: $google-red !important;
  }

  &.icon-info {
    color: $google-blue !important;
  }
}

.img-icon {
  max-height: 16px;
  max-width: 16px;
}