@import '../styles/variables';

.box {
  border-radius: 3px;
  margin-bottom: 20px;

  .row-split {
    >div:first-child {
      border-right: 1px solid $grey-200;
    }
  }

  ._wrapper {
    padding: 20px;
  }

  .title {
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
  }
  

  .box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .box-header-actions {
      display: flex;
      align-items: center;
    }

    .box-title {
      display: inline-block;
      font-size: 24px;
      font-weight: 500;
    }

    .btn {
      display: inline-block;
      vertical-align: top;
      margin: 2px 0;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn-form {
      border: 1px solid #ccc;
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      color: $blue-gray-400;
      text-decoration: none;
    }

    .btn-label {
      pointer-events: none !important;
    }

    .btn-edit-title {
      background-color: $secondary;
      color: white;
      font-size: 10px;
      height: 24px;
      width: 24px;
      margin-top: -4px;
      margin-left: 8px;
      border: none;
      border-radius: 3px;
      &:hover {
        background-color: darken($secondary, 5%);
        cursor: pointer;
        color: white;
      }
    }

    .box-tools {
      margin: 0;

      .text {
        min-height: 30px;
        line-height: 1;
        font-size: 13px;
        color: $grey-700;
        display: inline-block;
      }
    }

    .title-field-container{
      margin-right: 10px
    }

    .title-edit-box{
      margin-left: auto; 
      margin-right: auto;
      position: relative;
    }

    .interested-edit-box{
      margin-top: 7px;
    }
  }

  .box-body {
    background-color: #ffffff;
    border-radius: 5px;

    &.with-border {
      border: 1px solid $grey-300;
    }
  }

  .box-user {
    background-color: transparent;
  }

  // .box-body {
    
  // }

  &.box-default {
    .box-body {
      background-color: transparent;
    }
  }

  &.box-white {
    background-color: white;
    padding: 15px;
    border: 1px solid $grey-300;
  }

}

.section-body {
  margin: 15px 0;

  ._header {
    color: $grey-700;
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
  }

  .share-table {
    margin-top: 10px;
  }
}

.btn-label {
  pointer-events: none !important;
}

.exp-box {
  width: 100%;
  margin: 15px 0;

  .box-wrapper {
    padding: 0;
    width: 100%;
  }

  .box-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-title {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      padding: 10px;      
      color: $blue-gray-700;
    }

    .box-actions {

    }
  }

  .list-view {
    .list-item {
      background-color: #ffffff;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  .box-body {
    ._wrapper {
      padding: 10px;
    }
  }
}